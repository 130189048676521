var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field"},[(_vm.label.length > 0)?_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:{
      'department-combo': true,
      'opened': _vm.showDepartmentList,
      rounded: _vm.rounded
    },style:({
      width: _vm.width + 'px'
    })},[_c('div',{staticClass:"flexrow",on:{"click":_vm.toggleDepartmentList}},[(_vm.currentDepartment)?_c('div',{staticClass:"selected-department-line flexrow-item"},[_c('department-name',{attrs:{"department":_vm.currentDepartment}})],1):_vm._e(),_c('chevron-down-icon',{staticClass:"down-icon flexrow-item"})],1),(_vm.showDepartmentList)?_c('div',{ref:"select",staticClass:"select-input",style:({
       'max-height': _vm.maxHeightSelectInput + 'px',
       width: _vm.width + 'px'
      })},_vm._l((_vm.departmentList.filter(
          function (departement) { return departement.id !== this$1.value; })),function(department){return _c('div',{key:department.id,staticClass:"department-line",on:{"click":function($event){return _vm.selectDepartment(department)}}},[_c('department-name',{attrs:{"department":department}})],1)}),0):_vm._e()]),_c('combobox-mask',{attrs:{"displayed":_vm.showDepartmentList},on:{"click":_vm.toggleDepartmentList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }