var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-list task-list"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onBodyScroll),expression:"onBodyScroll"}],ref:"body",staticClass:"datatable-wrapper"},[_c('table',{staticClass:"datatable"},[_c('thead',{staticClass:"datatable-head"},[_c('tr',[_c('th',{ref:"th-prod",staticClass:"production datatable-row-header datatable-row-header--nobd",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.production'))+" ")]),_c('th',{ref:"th-type",staticClass:"type datatable-row-header datatable-row-header--nobd",style:({left: _vm.colTypePosX}),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.task_type'))+" ")]),_c('th',{ref:"th-name",staticClass:"name datatable-row-header",style:({left: _vm.colNamePosX}),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.entity'))+" ")]),_c('th',{staticClass:"description",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('assets.fields.description'))+" ")]),_c('th',{staticClass:"estimation",attrs:{"scope":"col","title":_vm.$t('main.estimation')}},[_vm._v(" "+_vm._s(_vm.$t('main.estimation_short'))+" ")]),_c('th',{staticClass:"estimation",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.duration').substring(0, 3))+". ")]),_c('th',{staticClass:"start-date",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.start_date_short'))+" ")]),_c('th',{staticClass:"due-date",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.due_date'))+" ")]),_vm._l((Object.keys(_vm.metadataDescriptorsMap)),function(field_name){return _c('metadata-header',{key:'desc-header' + field_name,attrs:{"descriptor":_vm.mergeMetadataDescriptors(
              _vm.metadataDescriptorsMap[field_name]),"no-menu":true}})}),_c('th',{staticClass:"status",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.task_status'))+" ")]),(!_vm.done)?_c('th',{staticClass:"last-comment",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.last_comment'))+" ")]):_c('th',{staticClass:"end-date",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.fields.end_date'))+" ")])],2)]),(_vm.tasks.length > 0)?_c('tbody',{staticClass:"datatable-body"},_vm._l((_vm.displayedTasks),function(entry,i){return _c('tr',{key:entry + '-' + i,class:{
            'datatable-row': true,
            'datatable-row--selectable': true,
            selected:
              _vm.selectionGrid && _vm.selectionGrid[i] ? _vm.selectionGrid[i][0] : false
          },on:{"click":function($event){return _vm.onLineClicked(i, $event)}}},[_c('td',{staticClass:"production datatable-row-header datatable-row-header--nobd",attrs:{"scope":"row"}},[_c('production-name-cell',{attrs:{"is-tooltip":true,"entry":_vm.productionMap.get(entry.project_id),"only-avatar":true}})],1),_c('task-type-cell',{staticClass:"type datatable-row-header datatable-row-header--nobd",style:({left: _vm.colTypePosX}),attrs:{"production-id":entry.project_id,"task-type":_vm.getTaskType(entry)}}),_c('td',{staticClass:"name datatable-row-header",style:({left: _vm.colNamePosX})},[_c('div',{staticClass:"flexrow"},[_c('entity-thumbnail',{attrs:{"empty-width":60,"empty-height":40,"entity":{preview_file_id: entry.entity_preview_file_id}}}),_c('router-link',{attrs:{"to":_vm.entityPath(entry)}},[_vm._v(" "+_vm._s(entry.full_entity_name)+" ")])],1)]),_c('description-cell',{staticClass:"description",attrs:{"entry":{description: entry.entity_description}}}),_c('td',{staticClass:"estimation"},[_vm._v(" "+_vm._s(_vm.formatDuration(entry.estimation))+" ")]),_c('td',{staticClass:"estimation"},[_vm._v(" "+_vm._s(_vm.formatDuration(entry.duration))+" ")]),_c('td',{staticClass:"start-date"},[_vm._v(" "+_vm._s(_vm.formatDate(entry.start_date))+" ")]),_c('td',{staticClass:"due-date"},[_vm._v(" "+_vm._s(_vm.formatDate(entry.due_date))+" ")]),_vm._l((Object.keys(_vm.metadataDescriptorsMap)),function(fieldName){return _c('td',{key:'desc-' + entry.id + '-' + fieldName,staticClass:"metadata-descriptor"},[(entry.entity_data &&
                _vm.getMetadataDescriptor(fieldName, entry))?_c('div',[(_vm.getDescriptorChecklistValues(
                  _vm.getMetadataDescriptor(fieldName, entry)).length > 0)?_c('div',_vm._l((_vm.getDescriptorChecklistValues(
                    _vm.getMetadataDescriptor(fieldName, entry))),function(option,i){return _c('p',{key:((entry.id) + "-\n                    " + (_vm.getMetadataDescriptor(fieldName, entry).id) + "\n                    -" + i + "-" + (option.text) + "-div")},[_c('input',{attrs:{"type":"checkbox","disabled":"","id":((entry.id) + "\n                      -" + (_vm.getMetadataDescriptor(fieldName, entry).id) + "\n                      -" + i + "-" + (option.text) + "-input")},domProps:{"checked":_vm.getMetadataChecklistValues(
                      _vm.getMetadataDescriptor(fieldName, entry)
                      , entry)[option.text]}}),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"for":((entry.id) + "\n                      -" + (_vm.getMetadataDescriptor(fieldName, entry).id) + "\n                      -" + i + "-" + (option.text) + "-input")}},[_vm._v(" "+_vm._s(option.text)+" ")])])}),0):_c('p',[_vm._v(" "+_vm._s(_vm.getMetadataFieldValue(_vm.getMetadataDescriptor(fieldName, entry) , entry))+" ")])]):_vm._e()])}),_c('validation-cell',{ref:'validation-' + i + '-0',refInFor:true,staticClass:"status unselectable",attrs:{"task-test":entry,"is-border":false,"is-assignees":false,"selectable":!_vm.done,"clickable":false,"selected":_vm.selectionGrid && _vm.selectionGrid[i] ? _vm.selectionGrid[i][0] : false,"rowX":i,"columnY":0,"column":entry.taskStatus},on:{"select":_vm.onTaskSelected,"unselect":_vm.onTaskUnselected}}),(!_vm.done)?_c('last-comment-cell',{staticClass:"last-comment",attrs:{"task":entry}}):_c('td',{staticClass:"end-date"},[_vm._v(" "+_vm._s(_vm.formatDate(entry.end_date))+" ")])],2)}),0):_vm._e()])]),_c('table-info',{attrs:{"is-loading":_vm.isLoading,"is-error":_vm.isError}}),(_vm.tasks.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"has-text-centered empty-list"},[_vm._m(0),_c('p',[_vm._v(" "+_vm._s(_vm.$t('people.no_task_assigned'))+" ")])]):_vm._e(),(_vm.tasks.length && !_vm.isLoading)?_c('p',{staticClass:"has-text-centered footer-info"},[_vm._v(" "+_vm._s(_vm.tasks.length)+" "+_vm._s(_vm.$tc('tasks.tasks', _vm.tasks.length))+" ")]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":require("../../assets/illustrations/empty_todo.png")}})])}]

export { render, staticRenderFns }