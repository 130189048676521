var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-list"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onBodyScroll),expression:"onBodyScroll"}],ref:"body",staticClass:"datatable-wrapper"},[_c('table-header-menu',{ref:"headerMenu",attrs:{"is-minimized":_vm.hiddenColumns[_vm.lastHeaderMenuDisplayed],"is-edit-allowed":_vm.isCurrentUserManager,"is-sticked":_vm.stickedColumns[_vm.lastHeaderMenuDisplayed]},on:{"minimize-clicked":function($event){return _vm.onMinimizeColumnToggled()},"delete-all-clicked":function($event){return _vm.onDeleteAllTasksClicked()},"sort-by-clicked":function($event){return _vm.onSortByTaskTypeClicked()},"select-column":_vm.onSelectColumn,"toggle-stick":function($event){return _vm.stickColumnClicked()}}}),_c('table-metadata-header-menu',{ref:"headerMetadataMenu",attrs:{"is-edit-allowed":_vm.isMetadataColumnEditAllowed(_vm.lastMetadaDataHeaderMenuDisplayed),"is-sticked":_vm.stickedColumns[_vm.lastMetadaDataHeaderMenuDisplayed]},on:{"edit-clicked":function($event){return _vm.onEditMetadataClicked()},"delete-clicked":function($event){return _vm.onDeleteMetadataClicked()},"sort-by-clicked":function($event){return _vm.onSortByMetadataClicked()},"toggle-stick":function($event){return _vm.metadataStickColumnClicked($event)}}}),_c('table',{staticClass:"datatable"},[_c('thead',{directives:[{name:"columns-resizable",rawName:"v-columns-resizable"}],staticClass:"datatable-head",attrs:{"id":"datatable-edit"}},[_c('tr',[(_vm.isTVShow)?_c('th',{ref:"th-episode",staticClass:"episode",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('edits.fields.episode'))+" ")]):_vm._e(),_c('th',{ref:"th-edit",staticClass:"name edit-name datatable-row-header",attrs:{"scope":"col"}},[_c('div',{staticClass:"flexrow"},[_c('span',{staticClass:"flexrow-item"},[_vm._v(" "+_vm._s(_vm.$t('edits.fields.name'))+" ")]),((_vm.isCurrentUserManager || _vm.isCurrentUserSupervisor)
                  && !_vm.isLoading)?_c('button-simple',{staticClass:"is-small flexrow-item",attrs:{"icon":"plus","text":''},on:{"click":_vm.onAddMetadataClicked}}):_vm._e()],1)]),_vm._l((_vm.stickedVisibleMetadataDescriptors),function(descriptor,j){return (_vm.isShowInfos)?_c('metadata-header',{key:descriptor.id,ref:("editor-" + j),refInFor:true,attrs:{"descriptor":descriptor,"left":_vm.offsets['editor-' + j] ? ((_vm.offsets['editor-' + j]) + "px") : '0',"is-stick":""},on:{"show-metadata-header-menu":function (event) { return _vm.showMetadataHeaderMenu(descriptor.id, event); }}}):_vm._e()}),_vm._l((_vm.stickedDisplayedValidationColumns),function(columnId,columnIndexInGrid){return (!_vm.isLoading)?_c('validation-header',{key:columnId,ref:("validation-" + columnIndexInGrid),refInFor:true,attrs:{"hidden-columns":_vm.hiddenColumns,"column-id":columnId,"validation-style":_vm.getValidationStyle(columnId),"left":_vm.offsets['validation-' + columnIndexInGrid] ? ((_vm.offsets['validation-' + columnIndexInGrid]) + "px") : '0',"type":"edits","is-stick":""},on:{"show-header-menu":function (event) { return _vm.showHeaderMenu(columnId, columnIndexInGrid, event); }}}):_vm._e()}),(!_vm.isCurrentUserClient && _vm.isShowInfos && _vm.isEditDescription)?_c('th',{staticClass:"description selectable",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('edits.fields.description'))+" ")]):_vm._e(),_vm._l((_vm.nonStickedVisibleMetadataDescriptors),function(descriptor){return (_vm.isShowInfos)?_c('metadata-header',{key:descriptor.id,attrs:{"descriptor":descriptor},on:{"show-metadata-header-menu":function (event) { return _vm.showMetadataHeaderMenu(descriptor.id, event); }}}):_vm._e()}),(!_vm.isCurrentUserClient &&
                  _vm.isShowInfos &&
                  _vm.isEditTime &&
                  _vm.metadataDisplayHeaders.timeSpent)?_c('th',{ref:"th-spent",staticClass:"time-spent",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('edits.fields.time_spent'))+" ")]):_vm._e(),(!_vm.isCurrentUserClient &&
                  _vm.isShowInfos &&
                  _vm.isEditEstimation &&
                  _vm.metadataDisplayHeaders.estimation)?_c('th',{ref:"th-spent",staticClass:"estimation",attrs:{"scope":"col","title":_vm.$t('main.estimation')}},[_vm._v(" "+_vm._s(_vm.$t('main.estimation_short'))+" ")]):_vm._e(),_vm._l((_vm.nonStickedDisplayedValidationColumns),function(columnId,columnIndexInGrid){return (!_vm.isLoading)?_c('validation-header',{key:columnId,attrs:{"hidden-columns":_vm.hiddenColumns,"column-id":columnId,"validation-style":_vm.getValidationStyle(columnId),"type":"edits"},on:{"show-header-menu":function (event) {
              _vm.showHeaderMenu(columnId, columnIndexInGrid, event)
            }}}):_vm._e()}),_c('th',{ref:"actionsSection",staticClass:"actions",attrs:{"scope":"col"}},[(_vm.isCurrentUserManager &&
                    _vm.displayedEdits.length > 0 &&
                    !_vm.isLoading)?_c('button-simple',{class:{
                'is-small': true,
                highlighted: _vm.isEmptyTask
              },attrs:{"icon":"plus","text":_vm.$t('tasks.create_tasks')},on:{"click":function($event){return _vm.$emit('create-tasks')}}}):_vm._e(),_c('table-metadata-selector-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.columnSelectorDisplayed && _vm.isShowInfos),expression:"columnSelectorDisplayed && isShowInfos"}],ref:"headerMetadataSelectorMenu",attrs:{"metadataDisplayHeaders":_vm.metadataDisplayHeaders,"descriptors":_vm.editMetadataDescriptors,"exclude":{
                timeSpent: !_vm.isEditTime,
                estimation: !_vm.isEditEstimation
              },"namespace":"edits"},on:{"update:metadataDisplayHeaders":function($event){_vm.metadataDisplayHeaders=$event},"update:metadata-display-headers":function($event){_vm.metadataDisplayHeaders=$event}}}),(_vm.editMetadataDescriptors.length > 0 && _vm.isShowInfos)?_c('button-simple',{staticClass:"is-small is-pulled-right",attrs:{"icon":"down"},on:{"click":_vm.toggleColumnSelector}}):_vm._e()],1)],2)]),_c('tbody',{staticClass:"datatable-body"},_vm._l((_vm.displayedEdits),function(edit,i){return (!_vm.isLoading && _vm.isListVisible)?_c('tr',{key:edit.id,staticClass:"datatable-row",class:{canceled: edit.canceled},attrs:{"scope":"row"}},[(_vm.isTVShow)?_c('td',{staticClass:"episode"},[_c('div',{staticClass:"flexrow"},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentUserClient),expression:"!isCurrentUserClient"}],staticClass:"mr1",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedEdits.has(edit.id)},on:{"input":function (event) { return _vm.toggleLine(edit, event); }}}),_vm._v(" "+_vm._s(_vm.episodeMap.get(edit.parent_id) ? _vm.episodeMap.get(edit.parent_id).name : '-')+" ")])]):_vm._e(),_c('th',{class:{
              'datatable-row-header': true,
              'edit-name': true,
              name: true,
              bold: !edit.canceled}},[_c('div',{staticClass:"flexrow"},[(!_vm.isTVShow)?_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCurrentUserClient),expression:"!isCurrentUserClient"}],staticClass:"mr1",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedEdits.has(edit.id)},on:{"input":function (event) { return _vm.toggleLine(edit, event); }}}):_vm._e(),_c('entity-thumbnail',{attrs:{"entity":edit,"width":_vm.isBigThumbnails ? 150 : 50,"height":_vm.isBigThumbnails ? 100 : 33,"empty-width":_vm.isBigThumbnails ? 150 : 50,"empty-height":_vm.isBigThumbnails ? 100 : 34}}),_c('router-link',{attrs:{"tabindex":"-1","title":edit.full_name,"to":_vm.editPath(edit.id)}},[_vm._v(" "+_vm._s(edit.name)+" ")])],1)]),_vm._l((_vm.stickedVisibleMetadataDescriptors),function(descriptor,j){return (_vm.isShowInfos)?_c('td',{key:edit.id + '-' + descriptor.id,ref:("editor-" + i + "-" + j),refInFor:true,staticClass:"metadata-descriptor datatable-row-header",style:({'left': _vm.offsets['editor-' + j] ? ((_vm.offsets['editor-' + j]) + "px") : '0'}),attrs:{"title":edit.data ? edit.data[descriptor.field_name] : ''}},[(descriptor.choices.length === 0 && (_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments)))?_c('input',{staticClass:"input-editor",domProps:{"value":_vm.getMetadataFieldValue(descriptor, edit)},on:{"input":function (event) { return _vm.onMetadataFieldChanged(edit, descriptor, event); },"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, i, j); }).apply(null, arguments)}}}):(_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments))?_c('span',{staticClass:"select"},[_c('select',{staticClass:"select-input",on:{"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, i, j); }).apply(null, arguments)},"change":function (event) { return _vm.onMetadataFieldChanged(edit, descriptor, event); }}},_vm._l((_vm.getDescriptorChoicesOptions(descriptor)),function(option,i){return _c('option',{key:((edit.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.label) + "-" + (option.value)),domProps:{"value":option.value,"selected":_vm.getMetadataFieldValue(descriptor, edit) === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)]):_c('span',{staticClass:"metadata-value selectable"},[_vm._v(" "+_vm._s(_vm.getMetadataFieldValue(descriptor, edit))+" ")])]):_vm._e()}),_vm._l((_vm.stickedDisplayedValidationColumns),function(columnId,j){return (!_vm.isLoading)?_c('validation-cell',{key:columnId + '-' + edit.id,ref:("validation-" + i + "-" + j),refInFor:true,class:{
              'validation-cell': !_vm.hiddenColumns[columnId],
              'hidden-validation-cell': _vm.hiddenColumns[columnId],
              'datatable-row-header': true
            },attrs:{"column":_vm.taskTypeMap.get(columnId),"columnY":j,"entity":edit,"is-assignees":_vm.isShowAssignations,"is-static":true,"left":_vm.offsets['validation-' + j] ? ((_vm.offsets['validation-' + j]) + "px") : '0',"minimized":_vm.hiddenColumns[columnId],"rowX":i,"selected":_vm.isSelected(i, j),"sticked":true,"task-test":_vm.taskMap.get(edit.validations.get(columnId))},on:{"select":function (infos) { return _vm.onTaskSelected(infos, true); },"unselect":function (infos) { return _vm.onTaskUnselected(infos, true); }}}):_vm._e()}),(!_vm.isCurrentUserClient && _vm.isShowInfos && _vm.isEditDescription)?_c('description-cell',{staticClass:"description",attrs:{"entry":edit,"editable":_vm.isCurrentUserManager},on:{"description-changed":function (value) { return _vm.onDescriptionChanged(edit, value); }}}):_vm._e(),_vm._l((_vm.nonStickedVisibleMetadataDescriptors),function(descriptor,j){return (_vm.isShowInfos)?_c('td',{key:edit.id + '-' + descriptor.id,staticClass:"metadata-descriptor",attrs:{"title":edit.data ? edit.data[descriptor.field_name] : ''}},[(descriptor.choices.length === 0 && (_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments)))?_c('input',{staticClass:"input-editor",domProps:{"value":_vm.getMetadataFieldValue(descriptor, edit)},on:{"input":function (event) { return _vm.onMetadataFieldChanged(edit, descriptor, event); },"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, i, j); }).apply(null, arguments)}}}):(_vm.isCurrentUserManager
              || _vm.isSupervisorInDepartments(descriptor.departments))?_c('span',{staticClass:"select"},[_c('select',{staticClass:"select-input",on:{"keyup":function($event){if(!$event.ctrlKey){ return null; }return (function (event) { return _vm.onInputKeyUp(event, i, j); }).apply(null, arguments)},"change":function (event) { return _vm.onMetadataFieldChanged(edit, descriptor, event); }}},_vm._l((_vm.getDescriptorChoicesOptions(descriptor)),function(option,i){return _c('option',{key:((edit.id) + "-" + (descriptor.id) + "-" + i + "-" + (option.label) + "-" + (option.value)),domProps:{"value":option.value,"selected":_vm.getMetadataFieldValue(descriptor, edit) === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)]):_c('span',{staticClass:"metadata-value selectable"},[_vm._v(" "+_vm._s(_vm.getMetadataFieldValue(descriptor, edit))+" ")])]):_vm._e()}),(!_vm.isCurrentUserClient && _vm.isShowInfos && _vm.isEditTime && _vm.metadataDisplayHeaders.timeSpent)?_c('td',{staticClass:"time-spent selectable"},[_vm._v(" "+_vm._s(_vm.formatDuration(edit.timeSpent))+" ")]):_vm._e(),(!_vm.isCurrentUserClient && _vm.isShowInfos && _vm.isEditEstimation && _vm.metadataDisplayHeaders.estimation)?_c('td',{staticClass:"estimation selectable"},[_vm._v(" "+_vm._s(_vm.formatDuration(edit.estimation))+" ")]):_vm._e(),_vm._l((_vm.nonStickedDisplayedValidationColumns),function(columnId,j){return (!_vm.isLoading)?_c('validation-cell',{key:(columnId + "-" + (edit.id)),ref:("validation-" + i + "-" + (j + _vm.stickedDisplayedValidationColumns.length)),refInFor:true,class:{
              'validation-cell': !_vm.hiddenColumns[columnId],
              'hidden-validation-cell': _vm.hiddenColumns[columnId]
            },attrs:{"column":_vm.taskTypeMap.get(columnId),"entity":edit,"task-test":_vm.taskMap.get(edit.validations
                        ? edit.validations.get(columnId)
                        : null
            ),"minimized":_vm.hiddenColumns[columnId],"selected":_vm.isSelected(i, j + _vm.stickedDisplayedValidationColumns.length),"rowX":i,"columnY":j,"is-assignees":_vm.isShowAssignations},on:{"select":_vm.onTaskSelected,"unselect":_vm.onTaskUnselected}}):_vm._e()}),(_vm.isCurrentUserManager)?_c('row-actions-cell',{attrs:{"entry":edit,"hide-history":false},on:{"delete-clicked":function($event){return _vm.$emit('delete-clicked', edit)},"edit-clicked":function($event){return _vm.$emit('edit-clicked', edit)},"history-clicked":function($event){return _vm.$emit('edit-history', edit)},"restore-clicked":function($event){return _vm.$emit('restore-clicked', edit)}}}):_c('td',{staticClass:"actions"})],2):_vm._e()}),0)])],1),_c('table-info',{attrs:{"is-loading":_vm.isLoading,"is-error":_vm.isError}}),(_vm.isEmptyList && !_vm.isCurrentUserClient && !_vm.isLoading)?_c('div',{staticClass:"has-text-centered"},[_vm._m(0),_c('p',{staticClass:"info"},[_vm._v(_vm._s(_vm.$t('edits.empty_list')))]),_c('button-simple',{staticClass:"level-item big-button",attrs:{"text":_vm.$t('edits.new_edits')},on:{"click":function($event){return _vm.$emit('add-edits')}}})],1):_vm._e(),(_vm.isEmptyList && _vm.isCurrentUserClient && !_vm.isLoading)?_c('div',{staticClass:"has-text-centered"},[_vm._m(1),_c('p',{staticClass:"info"},[_vm._v(_vm._s(_vm.$t('edits.empty_list_client')))])]):_vm._e(),(!_vm.isEmptyList && !_vm.isLoading)?_c('p',{staticClass:"has-text-centered nb-edits"},[_vm._v(" "+_vm._s(_vm.displayedEditsLength)+" "+_vm._s(_vm.$tc('edits.number', _vm.displayedEditsLength))+" ("+_vm._s(_vm.formatDuration(_vm.displayedEditsTimeSpent))+" "+_vm._s(_vm.$tc('main.days_spent', _vm.displayedEditsTimeSpent))+", "+_vm._s(_vm.formatDuration(_vm.displayedEditsEstimation))+" "+_vm._s(_vm.$tc('main.man_days', _vm.displayedEditsEstimation))+") ")]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"info"},[_c('img',{attrs:{"src":require("../../assets/illustrations/empty_edit.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"info"},[_c('img',{attrs:{"src":require("../../assets/illustrations/empty_edit.png")}})])}]

export { render, staticRenderFns }